import { OfferAmount, ProductAggregate } from '@obeta/schema'
import { MultiSelectionNumber, MultiSelectionString } from '../Meta/Meta'

export interface CartTemplate {
  id: string
  userId: string
  name: string
  isDefault: boolean
  isFavourite: boolean
  isShared: boolean
  itemCount: number
  userName: string
  cartTemplateItems?: { items?: CartTemplateItem[]; resultsCount?: number }
  templatePrices?: CartTemplatePrices
  // RFC3339 date-time string
  updatedAt: string
  createdAt: string
}

export interface CartTemplateItem {
  id: string
  templateId: string
  productId: string
  amount: number
  product: ProductAggregate
  // RFC3339 date-time string
  updatedAt: string
  sortDate: string
}

export interface CartTemplatePrices {
  netSum: number
  currency: string
}

export interface CartTemplateForDropdown {
  id: string
  name: string
  articleCount: number
}

export interface CartTemplateAddItemsInput {
  templateId: string
  itemsToAdd: CartTemplateItemInput[]
}

export interface CartTemplateAddCartTemplateInput {
  targetCartTemplateId: string
  originCartTemplateIds: string[]
}

export interface CartTemplateAddOfferInput {
  offerId: string
  offerName: string
  multiSelection: MultiSelectionNumber
  cartTemplateId: string
}

export interface DropDownTemplatesOffer {
  offerId: string
  offerName: string
  selectedItemCount: number
  multiSelection: MultiSelectionNumber
  modifiedOfferItemAmounts: OfferAmount[]
}

export interface DropDownOriginCartTemplate {
  cartTemplateId: string
  cartTemplateName: string
  selectedItemCount: number
  multiSelection: MultiSelectionString
}

export interface CartTemplateItemInput {
  productId: string
  amount: number
}

export interface CartTemplateDeleteInput {
  templateId: string
}

export interface CartTemplatePatch {
  name?: string
  isFavourite?: boolean
  isShared?: boolean
}

export interface CartTemplateUpdateInput {
  cartTemplateId: string
  patch: CartTemplatePatch
}

export interface CartTemplateDuplicateInput {
  cartTemplateId: string
}

export interface CartTemplatesListFilters {
  sortBy: SortBy
}

// Determines which dispatch should be called inside DropdownTemplates component (Add article to template or add cart templates to cart template)
export enum DropdownTemplatesType {
  ADD_ARTICLES = 'ADD_ARTICLE',
  ADD_TEMPLATES = 'ADD_TEMPLATE',
  ADD_TEMPLATE_ITEMS = 'ADD_TEMPLATE_ITEMS',
  ADD_OFFER = 'ADD_OFFER',
  ADD_ORDER_ITEMS = 'ADD_ORDER_ITEMS',
}

export enum Tab {
  ALL,
  FAVORITES,
  PRIVATE,
  PUBLIC,
}

export enum SortBy {
  LAST_USED = 'LAST_USED',
  LAST_UPDATED = 'LAST_UPDATED',
  ALPHABETICAL = 'ALPHABETICAL',
  PRIVATE_TEMPLATES = 'PRIVATE_TEMPLATES',
  PUBLIC_TEMPLATES = 'PUBLIC_TEMPLATES',
}
