import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/add_shopping_cart.svg'
import { ReactComponent as ArrowDownIcon } from '@obeta/assets/icon/arrow_down1.svg'
import React from 'react'
import styles from './SplitButton.module.scss'
import { LoadingIndicator } from './LoadingIndicator'
import { PrimarySplitButton } from '../custom-button/CustomButton'

export interface ISplitButtonProps
  extends Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  onArrowDownClicked: React.MouseEventHandler<HTMLButtonElement>
  title: string
  loading: boolean
  size?: 'small' | 'large'
  disabled?: boolean
  withoutTitle?: boolean
}

export const SplitButton = React.forwardRef<HTMLDivElement, ISplitButtonProps>(function SplitButton(
  props,
  ref
) {
  const { onArrowDownClicked, onClick, title, loading, size, disabled, withoutTitle } = props

  return (
    <div ref={ref} className={styles.root}>
      {loading && <LoadingIndicator />}
      <PrimarySplitButton
        rightIcon={<ArrowDownIcon />}
        size={size}
        leftIcon={<AddShoppingCartIcon />}
        onClick={onClick}
        rightIconOnClick={onArrowDownClicked}
        disabled={disabled}
      >
        {!withoutTitle && title}
      </PrimarySplitButton>
    </div>
  )
})
