import { Popover, PopoverProps as BasePopoverProps } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { Backdrop } from '../backdrop/Backdrop'
import { DropdownMenu, IDropdownMenuProps, IMenuClasses } from './DropdownMenu'
import styles from './PopoverDropdownMenu.module.scss'

export type PopoverMenuClasses = IMenuClasses & { paper?: string }

export interface PopoverProps extends Omit<BasePopoverProps, 'onClose'> {
  onClose(): void
  mobile?: boolean
  withBackdrop?: boolean
}

export interface IPopoverDropdownMenuProps<Option extends { id: string | number }>
  extends IDropdownMenuProps<Option>,
    PopoverProps {
  menuClasses?: PopoverMenuClasses
}

export interface IRefProps {
  popoverRef?: React.RefObject<HTMLDivElement>
  paperRef?: React.RefObject<HTMLDivElement>
}

export const BasePopover = React.forwardRef<HTMLDivElement, PopoverProps>((props, ref) => {
  const { withBackdrop, classes, mobile, ...restProps } = props

  return (
    <Popover
      ref={ref}
      keepMounted={false}
      transitionDuration={0}
      classes={{ ...classes, paper: clsx(classes?.paper, { [styles.popoverMobile]: mobile }) }}
      marginThreshold={0}
      slots={{
        backdrop: withBackdrop ? Backdrop : undefined,
      }}
      {...restProps}
    />
  )
})

export const PopoverDropdownMenu = <Option extends { id: string | number }>(
  props: IPopoverDropdownMenuProps<Option> & IRefProps
) => {
  const {
    open,
    onClose,
    menuClasses,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    withBackdrop,
    id,
    action,
    mobile,
    popoverRef,
    paperRef,
    keepMounted = false,
    ...rest
  } = props

  return (
    <BasePopover
      ref={popoverRef}
      PaperProps={{
        ref: paperRef,
      }}
      action={action}
      keepMounted={keepMounted}
      transitionDuration={0}
      classes={{ paper: menuClasses?.paper }}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      marginThreshold={0}
      open={open}
      slots={{
        backdrop: withBackdrop ? Backdrop : undefined,
      }}
      mobile={mobile}
      id={id}
    >
      <DropdownMenu menuClasses={menuClasses} {...rest} />
    </BasePopover>
  )
}
