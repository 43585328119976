import React, { FC, ImgHTMLAttributes, useState } from 'react'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { useOxomiData } from '@obeta/data/lib/hooks'
import {
  DEFAULT_WIDTH_IN_REM_DESKTOP,
  DEFAULT_WIDTH_IN_REM_MOBILE,
  DEFAULT_WIDTH_IN_REM_TABLET,
  DEFAULT_WIDTH_IN_REM_TABLET_WIDE,
  useImgProxyUrls,
} from '@obeta/data/lib/hooks/useImgProxyUrls'
import { ReactComponent as CardProductPlaceholder } from '@obeta/assets/img/CardProductPlaceholder.svg'
import { removeDuplicates } from '@obeta/utils/lib/array'

const DEFAULT_WIDTH = 400

export interface ImgProxyImage
  extends Omit<
    React.DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'width'
  > {
  url: string
  title?: string
  mobileWidthRem?: number
  tabletWidthRem?: number
  tabletWideWidthRem?: number
  desktopWidthRem?: number
  oxomiId?: string
  supplierId?: string
  width?: null | number
}

export const ImgProxyImage: FC<ImgProxyImage> = (props) => {
  const {
    width,
    className,
    title,
    mobileWidthRem = DEFAULT_WIDTH_IN_REM_MOBILE,
    tabletWidthRem = DEFAULT_WIDTH_IN_REM_TABLET,
    tabletWideWidthRem = DEFAULT_WIDTH_IN_REM_TABLET_WIDE,
    desktopWidthRem = DEFAULT_WIDTH_IN_REM_DESKTOP,
    oxomiId,
    supplierId,
    url,
    ...restProps
  } = props

  const [imageStatus, setImageStatus] = useState<'loading' | 'complete' | 'notStarted' | 'error'>(
    'notStarted'
  )
  const oxomiData = useOxomiData({ id: props.oxomiId ?? '', supplierId: props.supplierId ?? '' })
  const fallbackUrl = oxomiData.oxomiImages[0]?.large

  const { imgProxyUrlMobile, imgProxyUrlTablet, imgProxyUrlTabletWide, imgProxyUrlDesktop } =
    useImgProxyUrls(url, mobileWidthRem, tabletWidthRem, tabletWideWidthRem, desktopWidthRem)

  //We want to src using relevant imgproxy url, so we remove it here to prevent overwriting.
  restProps.src && delete restProps.src

  const srcSetEntries = [
    `${imgProxyUrlMobile}@webp ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlMobile}@avif ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlMobile}@png ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTablet}@webp ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTablet}@avif ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTablet}@png ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTabletWide}@webp ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTabletWide}@avif ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlTabletWide}@png ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlDesktop}@webp ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlDesktop}@avif ${width ?? DEFAULT_WIDTH}w,`,
    `${imgProxyUrlDesktop}@png ${width ?? DEFAULT_WIDTH}w,`,
  ]

  let alt = ''
  if (imageStatus === 'complete') {
    alt = title ?? ''
  }
  return imageStatus === 'notStarted' || imageStatus === 'complete' ? (
    <img
      id={url}
      src={imgProxyUrlMobile}
      onError={() => {
        restProps.src = url
        setImageStatus('error')
      }}
      onLoadStart={() => {
        setImageStatus('loading')
      }}
      onLoad={() => {
        setImageStatus('complete')
      }}
      className={className}
      alt={alt}
      srcSet={removeDuplicates(srcSetEntries).join('')}
      // TODO: fix sizes: width is always the same
      // TODO: use breakpoint dependent width values from props or remove sizes attribute
      sizes={`
        (max-width:${parseInt(scssVariables.screenSmMin)}px) ${width ?? DEFAULT_WIDTH}px,
        (max-width:${parseInt(scssVariables.screenMdMin)}px) ${width ?? DEFAULT_WIDTH}px,
        (max-width:${parseInt(scssVariables.screenLgMin)}px) ${width ?? DEFAULT_WIDTH}px,
        (min-width:${parseInt(scssVariables.screenLgMin)}px) ${width ?? DEFAULT_WIDTH}px,
        ${DEFAULT_WIDTH}px
      `}
      {...restProps}
    />
  ) : !fallbackUrl ? (
    <CardProductPlaceholder className={className} />
  ) : (
    <img id={url} src={fallbackUrl} alt={title ?? ''} className={className} {...restProps} />
  )
}
